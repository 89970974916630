import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { addITProvider } from '../../../../functions/addITProvider';
import { getInternetProvider } from '../../../../functions/getInternetProvider';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';
import { useLocation } from 'react-router-dom';

const NetworkProvider = () => {
    const [provider, setProvider] = useState(''); // Text input field state
    const { organization } = useContext(OrganizationContext);
    const { userLocation } = useContext(UserLocationContext);
    const routerLocation = useLocation(); // For React Router's location if needed

    const fetchProvider = async () => {
        try {
            console.log('Fetching existing provider for:', userLocation._id);
            const existingProvider = await getInternetProvider(userLocation._id);

            // Populate the text input field with the fetched provider, or keep it empty if no provider is found
            setProvider(existingProvider || '');
        } catch (error) {
            console.error('Error fetching existing provider:', error);
        }
    };

    useEffect(() => {
        fetchProvider(); // Fetch the existing provider on mount
    }, [userLocation._id]);

    const handleSubmit = async () => {
        try {
            await addITProvider(provider, userLocation._id);
            console.log('Submitted Provider:', provider);

            // Fetch the updated provider to reflect changes
            await fetchProvider();

            // Clear the input field after successful submission
            setProvider('');
        } catch (error) {
            console.error('Error submitting provider:', error);
        }
    };

    return (
        <Stack mx={5}>
            <Typography variant="h4" fontWeight="600">
                IT Provider
            </Typography>
            <Typography variant="h6" mt={1}>
                Please let us know who your internet provider is.
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                    label="Enter Provider (ex: Cloud5, SingleDigits, BlueprintRF, etc.)"
                    variant="outlined"
                    value={provider} // Controlled by the `provider` state
                    onChange={(e) => setProvider(e.target.value)} // Update the state on input change
                    sx={{ flex: 1 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    Submit
                </Button>
            </Box>
        </Stack>
    );
};

export default NetworkProvider;
