import React, { useContext } from 'react';
import { Grid, Stack } from '@mui/material';

import { body, home } from './styles';
import home_example from '../../../../assets/examples/home_example.png';
import home_example_2 from '../../../../assets/examples/home_example_2.png';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { showPreLaunch } from '../../../../utils/showPreLaunch';
import UserLocationMap from './components/UserLocationMap/UserLocationMap';
import AllTimeCarbonOffsetEquivalents from './components/AllTimeCarbonOffsetEquivalents/AllTimeCarbonOffsetEquivalents';
import OperatingSnapshot from './components/OperatingSnapshot/OperatingSnapshot';
import FoodDivertedFromLandfill from './components/AllTimeMetrics/FoodDivertedFromLandfill';
import EquivalentC02 from './components/AllTimeMetrics/EquivalentC02';
import InstallDate from './components/AllTimeMetrics/InstallDate';
import ExamplePageWarning from '../../components/ExamplePageWarning/ExamplePageWarning';

const Home = () => {
	const { organization } = useContext(OrganizationContext);

	if (!showPreLaunch(organization)) {
		return (
			<Stack direction='column' style={home}>
				<Grid container spacing={'1rem'}>
					<Grid item lg={3} md={3} xs={12} width='100%'>
						<UserLocationMap />
					</Grid>
					<Grid item lg={3} md={3} xs={12} width='100%'>
						<FoodDivertedFromLandfill />
					</Grid>
					<Grid item lg={3} md={3} xs={12} width='100%'>
						<EquivalentC02 />
					</Grid>
					<Grid item lg={3} md={3} xs={12} width='100%'>
						<InstallDate />
					</Grid>
				</Grid>

				<Stack direction='column' sx={body}>
					<AllTimeCarbonOffsetEquivalents />
					<OperatingSnapshot />
				</Stack>
			</Stack>
		);
	} else {
		return (
			<>
				<ExamplePageWarning />
				<img src={home_example} />
				<img src={home_example_2} />
			</>
		);
	}
};

export default Home;
