import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getHistoricalCovers } from '../../../../functions/getHistoricalCovers';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box } from '@mui/material';

const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const HistoricalCovers = () => {
	const { organization } = useContext(OrganizationContext);
	const [rows, setRows] = useState([]);

	const transformData = (data) => {
		const grouped = data.reduce((acc, { month, year, outletName, totalAct }) => {
			const monthYearKey = `${year}-${month}`;
			if (!acc[monthYearKey]) {
				acc[monthYearKey] = { id: monthYearKey, month, year, totals: {} };
			}
			acc[monthYearKey].totals[outletName] = totalAct || 0; // Handle null values by setting them to 0
			return acc;
		}, {});

		return Object.values(grouped).map((item) => {
			const total = Object.values(item.totals).reduce((sum, num) => sum + (num || 0), 0); // Handle null values in total calculation
			return {
				...item,
				total,
				monthName: monthNames[item.month - 1],
			};
		});
	};

	const generateColumns = (rows) => {
		const outletNames = rows.reduce((acc, row) => {
			Object.keys(row.totals).forEach((outlet) => {
				if (!acc.includes(outlet)) {
					acc.push(outlet);
				}
			});
			return acc;
		}, []);

		const columns = outletNames.map((outletName) => ({
			field: outletName,
			headerName: outletName,
			flex: 1,
			valueGetter: (params) => params.row.totals[outletName] || '-',
			valueFormatter: ({ value }) => value.toLocaleString(),
		}));

		columns.unshift(
			{ field: 'monthName', headerName: 'Month', flex: 1 },
			{ field: 'year', headerName: 'Year', flex: 1 },
			{
				field: 'total',
				headerName: 'Total',
				valueFormatter: ({ value }) => value.toLocaleString(),
				flex: 1,
			}
		);

		return columns;
	};

	useEffect(() => {
		async function fetchData() {
			const response = await getHistoricalCovers(organization._id);
			const transformedData = transformData(response);
			setRows(transformedData);
		}
		fetchData();
	}, [organization]);

	// Generate the columns for the DataGrid based on the outlet names
	const columns = generateColumns(rows);

	return (
		<div style={{ minHeight: 600, maxHeight: '80vh', width: '100%' }}>
			{rows.length > 0 ? (
				<DataGrid
					rows={rows}
					columns={columns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					sx={{ border: 0 }}
				/>
			) : (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
					<Typography variant="body1" fontWeight="400" sx={{ textAlign: 'center', marginTop: '2rem' }}>
						No Historical Covers. Make sure to enter your Projected & Actual Cover Counts!
					</Typography>
				</Box>
			)}
		</div>
	);
};

export default HistoricalCovers;
