import { Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Card from '../../../../components/Card';

const Accesory = ({ link, title, img, attributes, useCase }) => {
	const openInNewTab = () => {
		window.open(link, '_blank', 'noopener,noreferrer');
	};
	return (
		<Grid item xs={12} md={6} xl={4} sx={{ spacing: '1rem' }}>
			<Card
				onClick={openInNewTab}
				boxStyles={{
					display: 'flex',
					flexDirection: 'column',
					cursor: 'pointer',
					justifyContent: 'space-evenly',
					textAlign: 'center',
					height: '60vh',
					alignItems: 'center',
				}}>
				<Tooltip title='Open link in new tab' placement='bottom'>
					<Stack
						direction='row'
						gap='1rem'
						sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
						<Typography variant='h6' fontWeight='600'>
							{title}
						</Typography>
						<OpenInNewIcon />
					</Stack>
				</Tooltip>

				<img src={img} alt='' style={{ height: '25vh' }} />
				<Typography variant='p' textAlign='center' fontWeight='600'>
					{attributes}
				</Typography>
				<Typography variant='body'>{useCase}</Typography>
			</Card>
		</Grid>
	);
};

export default Accesory;
