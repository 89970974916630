import { useContext, useEffect, useState } from 'react';

import {
	Stack,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	CircularProgress,
} from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { getRenderings } from '../../../../functions/getRenderings';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';
import { getRenderFromS3 } from '../../../../functions/getRenderFromS3';

const Renders = () => {
	const [renders, setRenders] = useState([]);
	const { userLocation } = useContext(UserLocationContext);
	const [selectedRender, setSelectedRender] = useState();
	const [imageUrl, setImageUrl] = useState();
	const [loading, setLoading] = useState();

	useEffect(() => {
		const fetch = async () => {
			const response = await getRenderings(userLocation.locationName);
			setRenders(response);
			setSelectedRender(response[0]);
		};
		fetch();
	}, [userLocation]);

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			try {
				const response = await getRenderFromS3(selectedRender?.fileName);
				setImageUrl('data:image/png;base64,' + response);
				setLoading(false);
			} catch (err) {
				console.log(err);
			}
		};

		selectedRender && fetch();
	}, [selectedRender]);

	const nextRender = () => {
		const currentIndex = renders.indexOf(selectedRender);

		if (currentIndex < renders.length - 1) {
			setSelectedRender(renders[renders.indexOf(selectedRender) + 1]);
		}
	};

	const prevRender = () => {
		const currentIndex = renders.indexOf(selectedRender);

		if (currentIndex >= 1) {
			setSelectedRender(renders[renders.indexOf(selectedRender) - 1]);
		}
	};

	const isSelected = (renderId) => selectedRender && selectedRender._id === renderId;

	return (
		<Stack direction='column' mx={5}>
			<Typography variant='h4' fontWeight='600'>
				Renders
			</Typography>
			<Typography variant='h6' mt={1}>Below are the approved renders for your machine install </Typography>

			<Stack direction='column' height='100%' mt={5} gap={'1rem'}>
				<Box
					component={selectedRender ? '' : Paper}
					style={{
						width: '100%',
						minHeight: '45vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '0.5rem',
						backgroundColor: selectedRender ? 'transparent' : 'white',
					}}>
					{loading ? (
						<CircularProgress sx={{ justifySelf: 'center' }} />
					) : imageUrl ? (
						<>
							<Box
								sx={{
									position: 'relative',
									display: 'inline-block',
									width: '100%',
								}}>
								<img
									src={imageUrl}
									alt=''
									style={{ borderRadius: '0.5rem', width: '100%' }}
								/>
								<Box
									sx={{
										position: 'absolute',
										top: 0,
										bottom: 0,
										left: 0,
										width: '25%',
										backgroundColor: 'rgba(0, 0, 0, 0.3)',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										opacity: 0,
										transition: 'opacity 0.3s',
										cursor: 'pointer',
										borderRadius: '0.5rem 0 0 0.5rem',
										'&:hover': {
											opacity: 1,
										},
									}}
									onClick={prevRender}>
									<Box
										sx={{
											color: 'white',
											fontSize: '2rem',
											pointerEvents: 'none',
										}}>
										<KeyboardArrowLeftIcon fontSize='large' />
									</Box>
								</Box>
								<Box
									sx={{
										position: 'absolute',
										top: 0,
										bottom: 0,
										right: 0,
										width: '25%',
										backgroundColor: 'rgba(0, 0, 0, 0.3)',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										opacity: 0,
										transition: 'opacity 0.3s',
										cursor: 'pointer',
										borderRadius: '0 0.5rem 0.5rem 0',
										'&:hover': {
											opacity: 1,
										},
									}}
									onClick={nextRender}>
									<Box
										sx={{
											color: 'white',
											fontSize: '2rem',
											pointerEvents: 'none',
										}}>
										<KeyboardArrowRightIcon fontSize='large' />
									</Box>
								</Box>
							</Box>
						</>
					) : (
						<div
							style={{
								height: '100%',
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							There are currently no approved renders
						</div>
					)}
				</Box>
				<div
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<TableContainer component={Paper} sx={{ borderRadius: '0.5rem' }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Description</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{renders.map((render) => (
									<TableRow
										key={render._id}
										onClick={() => setSelectedRender(render)}
										sx={{
											backgroundColor: isSelected(render._id) ? '#f4f4f4' : 'none',
											cursor: 'pointer',
										}}>
										<TableCell component='th' scope='row'>
											{render.description}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</Stack>
		</Stack>
	);
};

export default Renders;
