import axios from 'axios';

export const requestLanguage = async (language, locationID) => {
	const url = `/.netlify/functions/requestLanguage`;

	try {
		const res = await axios.post(url, { language, locationID });
		return res.data;
	} catch (err) {
		console.error('Error:', err);
		return { error: err.response?.data || 'An unknown error occurred' };
	}
};
