import { useEffect, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

import { updateProjectedCovers } from '../../../../functions/updateProjectedCovers';
import { getOperatorCalendar } from '../../../../functions/getOperatorCalendar';
import ProjectedTableMessaging from './ProjectedTableMessaging';

function createData(id, outletID, outletName, week1, week2, week3, week4, week5) {
	if (week5 === undefined) {
		return { id, outletID, outletName, week1, week2, week3, week4 };
	} else return { id, outletID, outletName, week1, week2, week3, week4, week5 };
}

const formatWeekRange = (weekData) => {
	const startDate = new Date(weekData.weekStart);
	const endDate = new Date(weekData.weekEnd);
	return `Week of (${startDate.getMonth() + 1}/${startDate.getDate()} - ${
		endDate.getMonth() + 1
	}/${endDate.getDate()})`;
};

export const ProjectedTable = ({ organization, outlets, year, month }) => {
	const [rows, setRows] = useState([]);
	const [calendarData, setCalendarData] = useState();

	useEffect(() => {
		if (outlets && outlets.length > 0) {
			if (calendarData && calendarData.length === 5) {
				const newRows = outlets.map((outlet, index) =>
					createData(
						index,
						outlet.outletID,
						outlet.outletName,
						outlet[`week1proj`],
						outlet[`week2proj`],
						outlet[`week3proj`],
						outlet[`week4proj`],
						outlet[`week5proj`]
					)
				);

				setRows(newRows);
			} else if (calendarData) {
				const newRows = outlets.map((outlet, index) =>
					createData(
						index,
						outlet.outletID,
						outlet.outletName,
						outlet[`week1proj`],
						outlet[`week2proj`],
						outlet[`week3proj`],
						outlet[`week4proj`]
					)
				);
				setRows(newRows);
			}
		} else {
			setRows([]);
		}
	}, [outlets, calendarData, month]);

	useEffect(() => {
		if (month && year) {
			async function fetchData() {
				const response = await getOperatorCalendar(year, month);
				setCalendarData(response);
			}
			fetchData();
		}
	}, [month, year]);

	const handleProjectedChange = (id, week, value) => {
		const parsedValue = value === '' ? null : parseInt(value);
		setRows((prevRows) =>
			prevRows.map((row) => (row.id === id ? { ...row, [`week${week}`]: parsedValue } : row))
		);
	};

	const handleSubmit = (id, week) => {
		const row = rows.find((row) => row.id === id);
		const value = row[`week${week}`];
		updateProjectedCovers(organization, year, month, week, row.outletID, value);
	};

	return year && month ? (
		<TableContainer component={Paper}>
			<Table sx={{ width: '100%' }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell align='left'>Outlet Name</TableCell>
						{calendarData &&
							calendarData.map((weekData, index) => (
								<TableCell key={index} align='right'>
									{formatWeekRange(weekData)}
								</TableCell>
							))}
					</TableRow>
				</TableHead>

				<TableBody>
					{rows?.map((row) => (
						<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component='th' scope='row' align='left'>
								{row.outletName}
							</TableCell>
							{[1, 2, 3, 4, 5].map(
								(week) =>
									row[`week${week}`] !== undefined && (
										<TableCell key={week} align='right'>
											<input
												type='text'
												value={row[`week${week}`] !== null ? row[`week${week}`] : ''}
												onChange={(e) =>
													handleProjectedChange(row.id, week, e.target.value)
												}
												style={{ marginRight: '10px' }} // Spacing between input and button
											/>
											<button
												onClick={() => handleSubmit(row.id, week)}
												style={{
													backgroundColor: 'black',
													color: 'white',
													border: 'none',
													padding: '5px 10px',
													cursor: 'pointer',
													fontWeight: 'bold',
												}}>
												SUBMIT
											</button>
										</TableCell>
									)
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	) : (
		<ProjectedTableMessaging mo={month} yr={year} rws={rows}></ProjectedTableMessaging>
	);
};
