import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Stack, Typography } from '@mui/material';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getTopUsersLast30Days } from '../../../../../../../../functions/getTopUsersLast30Days';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const TopUsersLast30Days = () => {
	const [feedWeights, setFeedWeights] = useState([]);
	const [users, setUsers] = useState([]);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getTopUsersLast30Days(organization._id);
			if (response.users && response.weights) {
				setUsers(response.users);
				setFeedWeights(response.weights);
			}
		}
		fetchData();
	}, [organization]);

	const data = {
		labels: users,
		datasets: [
			{
				label: 'Food Recycled (lbs)',
				data: feedWeights,
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				borderColor: 'rgba(53, 162, 235, 0.8)',
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				align: 'end',
				anchor: 'end',
				formatter: (value) => value.toFixed(0), // Limit to 1 decimal place
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Food Recycled (lbs)',
				},
			},
			x: {
				title: {
					display: true,
					text: 'Users',
				},
			},
		},
	};

	return (
		<Stack
			direction={'column'}
			style={{
				alignItems: 'center',
				padding: '1rem',
				// height: '100%',
				width: '100%',
			}}>
			<Typography variant='h5' textAlign={'center'}>
				Top Users (Last 30 Days)
			</Typography>
			<Bar data={data} options={options} />
		</Stack>
	);
};

export default TopUsersLast30Days;
