import React, { useContext } from 'react';
import { desktopStyles, mobileStyles } from './styles';
import { Divider, Paper, Tooltip, IconButton, useTheme, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import SchoolIcon from '@mui/icons-material/School';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import TvIcon from '@mui/icons-material/Tv';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

import { BrandLogo } from './BrandLogo';
import SidebarItem from './SidebarItem';

import { OrganizationContext } from '../../../../context/OrganizationContext';
import { UserLocationContext } from '../../../../context/UserLocationContext';

import poweredByBioGreen from '../../../../assets/PoweredByBioGreen.svg';
import { isValidDate } from '../../../../utils/isValidDate';
import { UploadBrandLogo } from './UploadBrandLogo';
import { showPreLaunch } from '../../../../utils/showPreLaunch';
import { RocketLaunch } from '@mui/icons-material';

const Sidebar = ({ tab, setTab, isMobile, showMobileSidebar, setShowMobileSidebar }) => {
	const theme = useTheme();

	const { organization } = useContext(OrganizationContext);
	const { userLocation } = useContext(UserLocationContext);

	const handleSetTab = (page) => {
		setTab(page);
	};

	const handleCloseSidebar = () => {
		setShowMobileSidebar(false);
	};

	return (
		<Paper
			style={isMobile ? mobileStyles : desktopStyles}
			sx={{
				border: `1px solid ${theme.palette.border.main}`,
				boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)',
			}}>
			{isMobile && (
				<IconButton
					onClick={handleCloseSidebar}
					sx={{ position: 'absolute', top: 0, left: 0, margin: '1rem' }}>
					<CloseIcon />
				</IconButton>
			)}
			{organization.logo ? <BrandLogo /> : <UploadBrandLogo />}
			<Divider sx={{ width: '100%', mt: 2 }} />
			<Typography variant='p' sx={{ margin: 1 }}>
				{userLocation?.locationAddress}
			</Typography>
			<Divider sx={{ width: '100%' }} />

			<div
				style={{
					height: '100%',
					width: '90%',
					alignItems: 'flex-start',
					padding: '2rem',
				}}>
				{!isMobile && showPreLaunch(organization) && (
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Pre-Launch');
							handleCloseSidebar();
						}}
						icon={<RocketLaunch />}
						label='Pre-Launch'
					/>
				)}

				<SidebarItem
					tab={tab}
					action={() => {
						handleSetTab('Home');
						handleCloseSidebar();
					}}
					icon={<HomeIcon />}
					label='Home'
				/>
				{!isMobile && (
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Reports');
							handleCloseSidebar();
						}}
						icon={<BarChartIcon />}
						label='Reports'
					/>
				)}
				{!isMobile && organization.hasCovers === true ? (
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Zero Waste Events');
							handleCloseSidebar();
						}}
						icon={<CelebrationIcon />}
						label='Zero Waste Events'
					/>
				) : null}
				{!isMobile && organization.hasCovers === true ? (
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Covers');
							handleCloseSidebar();
						}}
						icon={<RestaurantIcon />}
						label='Covers'
					/>
				) : null}
				{/* {organization.hasZFWE && user.hasZFWEAccess ? ( */}

				{!isMobile && !showPreLaunch(organization) && (
	<SidebarItem
		tab={tab}
		action={() => {
			handleSetTab('Accessories');
			handleCloseSidebar();
		}}
		icon={<LocalGroceryStoreIcon />}
		label='Accessories'
	/>
)}
				{!isMobile && (
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Training');
							handleCloseSidebar();
						}}
						icon={<SchoolIcon />}
						label='Training'
					/>
				)}
				{!isMobile && (
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Users');
							handleCloseSidebar();
						}}
						icon={<PersonIcon />}
						label='Users'
					/>
				)}

				{!isMobile && (
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Settings');
							handleCloseSidebar();
						}}
						icon={<SettingsIcon />}
						label='Settings'
					/>
				)}

				{!isMobile && !showPreLaunch(organization) && (
					<Tooltip title='Open HUD  in new tab' placement='bottom'>
						<div>
							<SidebarItem
								tab={tab}
								action={() => {
									window.open(`/hud?query=${organization._id}`);
								}}
								icon={<TvIcon />}
								label='Open HUD'
							/>
						</div>
					</Tooltip>
				)}
			</div>
			<Divider sx={{ width: '100%' }} />
			{!isMobile && (
				<img src={poweredByBioGreen} alt='' style={{ width: '90%', margin: '1rem' }} />
			)}
		</Paper>
	);
};

export default Sidebar;
