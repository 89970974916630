import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import React from 'react';

const UploadLogo = () => {
	return (
		<Stack mx={5}>
			<Typography variant='h4' fontWeight='600'>
				Brand Setup
			</Typography>
			<Typography variant='h6' mt={1}>
				Upload your brand logo to the cloud portal
			</Typography>
			<Box sx={{ mt: 2 }}>
				<List>
					<ListItem>
                    • In the top left corner of this page, simply click to upload a logo file for your organization or hotel. This will be displayed on the cloud portal as well as on any PDF reports generated.
					</ListItem>
					<ListItem>
                    • In the Settings tab on the left sidebar, update your brand colors for your Zero Food Waste Event (ZFWE) reporting.
					</ListItem>
				</List>
			</Box>
		</Stack>
	);
};

export default UploadLogo;