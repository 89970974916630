import { Typography } from '@mui/material';

import MissingCoversMessage from './MissingCoversMessage';

const HeaderSection = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography variant='h6' fontWeight='600' sx={{ textAlign: 'center' }}>
				Record Cover Information
			</Typography>
			<ul>
				<li>
					<Typography variant='p' fontWeight='600'>
						Enter weekly cover information at the end of each week.
					</Typography>
				</li>
				<li>
					<Typography variant='p' fontWeight='600'>
						Make sure these figures are confirmed accurate for each outlet, and not
						projections or estimates
					</Typography>
				</li>
				<li>
					<Typography variant='p' fontWeight='600'>
						Information being entered into the table below should match the dates indicated in
						the column headers
					</Typography>
				</li>
				<li>
					<Typography variant='p' fontWeight='600'>
						To go back to a previous month, use the dropdown month & year selectors below to
						adjust the period being updated
					</Typography>
				</li>
			</ul>
			<MissingCoversMessage />
		</div>
	);
};

export default HeaderSection;
