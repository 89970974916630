import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import React from 'react';

const Network = () => {
	return (
		<Stack mx={5}>
			<Typography variant='h4' fontWeight='600'>
				Network Requirements
			</Typography>
			<Typography variant='h6' mt={1}>
				Complete Network/IT requirements as detailed below
			</Typography>
			<Box sx={{ mt: 2 }}>
				<List>
				<ListItem>
						• The machine's internet is set up on an guest or isolated network that is not the organization's main firewall, allowing us to streamline the process and avoid unneccesary restrictions.
					</ListItem>
					<ListItem>
						• Whitelist the machine(s)'s MAC address(es) for general internet access (MAC address to be provided).
					</ListItem>
					<ListItem>
						• 1 to 1 NAT through the network's firewall with source ACLs of 23.25.193.86,
						71.174.235.63, 98.216.211.225, and 24.62.160.238 for UDP traffic both in and out
						of port 3636
					</ListItem>
				</List>
			</Box>
		</Stack>
	);
};

export default Network;
