import { useContext, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getFoodWastePerCoverByMonthAndYear } from '../../../../../../functions/getFoodWastePerCoverByMonthAndYear';

Chart.register(ChartDataLabels);

const lineColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

const FoodWastePerCoverByMonthAndYear = () => {
	const { organization } = useContext(OrganizationContext);
	const [chartData, setChartData] = useState({ labels: [], datasets: [] });

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getFoodWastePerCoverByMonthAndYear(organization._id);
				if (response) {
					formatData(response);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}

		const formatData = (response) => {
			const months = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			];

			const dataByLocation = {};
			Object.entries(response).forEach(([location, monthlyData]) => {
				Object.entries(monthlyData).forEach(([yearMonth, value]) => {
					const [year, month] = yearMonth.split('-');
					if (!dataByLocation[location]) {
						dataByLocation[location] = {};
					}
					if (!dataByLocation[location][year]) {
						dataByLocation[location][year] = Array(12).fill(null); // Initialize with 12 months
					}
					dataByLocation[location][year][parseInt(month, 10) - 1] = value; // Map value to the correct month
				});
			});

			const datasets = [];
			Object.entries(dataByLocation).forEach(([location, years], locationIndex) => {
				Object.entries(years).forEach(([year, monthlyValues]) => {
					datasets.push({
						label: `${year}`,
						data: monthlyValues,
						borderColor: lineColors[locationIndex + (year % lineColors.length)],
						backgroundColor: 'transparent',
						fill: false,
					});
				});
			});

			setChartData({
				labels: months,
				datasets,
			});
		};

		fetchData();
	}, [organization]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top',
			},

			datalabels: {
				display: true,
				color: 'black',
				formatter: (value) => (value ? value.toFixed(1) : ''),
			},
		},
		scales: {
			y: {
				title: {
					display: true,
					text: 'Avg Food Recycled Per Cover (Ounces)',
				},
				beginAtZero: true,
			},
			x: {
				title: {
					display: true,
					text: 'Month',
				},
			},
		},
	};

	return (
		<Stack
			sx={{
				height: '35vh',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Typography variant='h6' fontWeight='600' alignSelf='flex-start'>
				Food Recycled Per Cover By Month and Year
			</Typography>
			<Box sx={{ height: '100%', width: '100%' }}>
				<Line options={options} data={chartData} />
			</Box>
		</Stack>
	);
};

export default FoodWastePerCoverByMonthAndYear;
