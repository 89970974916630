import { Grid } from '@mui/material';

import BussingCart from '../../../../assets/machine_accessories/BussingCart.jpeg';
import ArmLengthGlove from '../../../../assets/machine_accessories/ArmLengthGlove.jpg';
import GrillTongs from '../../../../assets/machine_accessories/GrillTongs.jpg';
import NitrileGloves from '../../../../assets/machine_accessories/NitrileGloves.jpg';
import PlasticTubLid from '../../../../assets/machine_accessories/PlasticTubLid.jpeg';
import PlasticTub from '../../../../assets/machine_accessories/PlasticTub.webp';
import Accessory from './Accesory';

const Accessories = () => {
	return (
		<Grid container px={'1rem'} spacing={'1rem'}>
			<Accessory
				link='https://www.webstaurantstore.com/choice-green-utility-bussing-cart-with-three-shelves-42-x-20/109CARTBLGGN.html?utm_source=google&utm_medium=cpc&utm_campaign=GoogleShopping&gclid=CjwKCAjwqZSlBhBwEiwAfoZUIOiwu211ArsNAiR8UfYuZWqmxxRj3bv7Uou8D_9L2VIdCKC_HGoBqBoCOAYQAvD_BwE'
				title='Choice Green Utility/Bussing Cart'
				img={BussingCart}
				attributes='3 Shelf Design, 42” x 20” dimensions.'
				useCase='Cleanly and effectively transport food waste from the source, to the
							BG3, and back. This lightweight, dependable cart system is the first step to an
							organized collection system that mitigates heavy lifting, and assists in
							keeping social costs at bay.'
			/>
			<Accessory
				link='https://www.webstaurantstore.com/choice-20-x-15-x-5-black-polyethylene-plastic-bus-tub-bus-box/176BT20155BK.html'
				title='Choice Black Plastic Bus Tubs'
				img={PlasticTub}
				attributes='Durable and stackable design, 20” x 15” dimensions.'
				useCase='Eliminate social costs when storing, or transporting food waste.
							These bins, because of their size, will eliminate heavy lifting. These bins
							also allow staff to easily scan full buckets for potential contaminants. Item 2
							(bins) and Item 3 (lids) must be paired together to achieve best results.'
			/>
			<Accessory
				link='https://www.webstaurantstore.com/choice-20-x-15-polyethylene-plastic-bus-tub-bus-box-lid-black/176BTLIDBK.html'
				title='Choice Black Plastic Bus Lids'
				img={PlasticTubLid}
				attributes='Durable and stackable design, 20” x 15” dimensions'
				useCase='Couple with Item 2 (Choice Plastic Bus Tubs), to achieve cleanliness
							when transporting food waste around the facility. These lids are the key
							contributing factor to eliminating spillage, therefore mitigating social costs
							on a daily basis.'
			/>
			<Accessory
				link='https://www.amazon.com/Disposable-Plastic-Dressing%EF%BC%8C-Shoulder-Gloves%EF%BC%8C39/dp/B08R5N77PH/ref=sr_1_6_pp?crid=EEANQNW7E0LR&keywords=arm+length+disposable+gloves&qid=1695648377&sprefix=arm+le%2Caps%2C125&sr=8-6'
				title='Meiweister Long Disposable Gloves'
				img={ArmLengthGlove}
				attributes='Disposable, arm length gloves for complete protection against external materials. Water and oil resistant.'
				useCase='Effective tool to be used when cleaning or clearing contaminant jams.
							This allows you to reach into the BG3’s tank without fear or lingering mess or
							smell coming into contact with skin. Easily removable, and disposable for
							convenience.'
			/>
			<Accessory
				link='https://www.amazon.com/Kona-Premium-Stainless-Steel-Locking-Sturdy/dp/B07ZTS6C3Z/ref=sr_1_25?crid=27U6RTRXMRQRU&keywords=large%2Btongs&qid=1701799101&sprefix=large%2Btongs%2Caps%2C98&sr=8-25&th=1'
				title='Kona Grill Tongs'
				img={GrillTongs}
				attributes='Stainless steel, 18” reach.'
				useCase='An effective tool in resolving bridging, or removal of contaminants
							without the need to use your hands in direct contact with material. Highly
							recommended. Easily cleaned.'
			/>
			<Accessory
				link='https://www.amazon.com/ForPro-Disposable-Powder-Free-Latex-Free-Non-Sterile/dp/B09JGPVMBB/ref=sr_1_5?crid=1B6WLJOUV2VIK&keywords=large+gloves&qid=1701799208&sprefix=large+gloves%2Caps%2C120&sr=8-5'
				title='ForPro Disposable Nitrile Gloves'
				img={NitrileGloves}
				attributes='Chemical and puncture resistant. Food safe, latex free.'
				useCase='The perfect all-around glove. Whether you are maintaining exterior
							cleanliness, handling food waste, or assisting in contaminant removal. It is
							easy and essential to protect your hands. Dispose after use.'
			/>
		</Grid>
	);
};

export default Accessories;
