import axios from 'axios';

export const getInternetProvider = async (locationID) => {
    const url = `/.netlify/functions/getInternetProvider`;

    try {
        const res = await axios.post(url, { locationID });
        return res.data.internetProvider; // Return the provider
    } catch (err) {
        console.error('Error fetching internet provider:', err);
        return null; // Return null on error
    }
};
