import { Grid, Tooltip } from '@mui/material';

import AllTimeFoodRecycled from './components/AllTimeFoodRecycled';
import AvgUptimeLast30Days from './components/AvgUptimeLast30Days';
import AllTimeCarbonOffsets from './components/AllTimeCarbonOffsets';
import Card from '../../../../../../components/Card';
import UserLocationMap from './components/UserLocationMap/UserLocationMap';

const TopRow = () => {
	return (
		<Grid container spacing='1rem'>
			<Grid item xs={12} lg={3}>
				<UserLocationMap />
			</Grid>
			<Grid item xs={12} lg={3}>
				<Card
					paperStyles={{
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Tooltip
						title='Total amount of food diverted from landfill in tons'
						placement='bottom'>
						<div>
							<AllTimeFoodRecycled />
						</div>
					</Tooltip>
				</Card>
			</Grid>
			<Grid item xs={12} lg={3}>
				<Card
					paperStyles={{
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Tooltip title='Equivalent tons of CO₂ Sequestered' placement='bottom'>
						<div>
							<AllTimeCarbonOffsets />
						</div>
					</Tooltip>
				</Card>
			</Grid>
			<Grid item xs={12} lg={3}>
				<Card
					paperStyles={{
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Tooltip title='Average % of time your machines are on' placement='bottom'>
						<div>
							<AvgUptimeLast30Days />
						</div>
					</Tooltip>
				</Card>
			</Grid>
		</Grid>
	);
};

export default TopRow;
