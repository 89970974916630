import FoodWastePerCoverByMonthAndYear from './components/FoodWastePerCoverByMonthAndYear/FoodWastePerCoverByMonthAndYear';
import TotalCoversByMonthAndYear from './components/TotalCoversByMonthAndYear/TotalCoversByMonthAndYear';
import AllTimeMetrics from './components/AllTimeMetrics/AllTimeMetrics';
import { Box, Stack } from '@mui/material';

const AllTimeTrends = () => {
	return (
		<Box sx={{ width: '100%', minHeight: '70vh' }}>
			<Stack direction='column' sx={{ gap: '1rem', margin: '1rem', alignItems: 'center' }}>
				<AllTimeMetrics />
				<>
					<TotalCoversByMonthAndYear />
					<FoodWastePerCoverByMonthAndYear />
				</>
			</Stack>
		</Box>
	);
};

export default AllTimeTrends;
