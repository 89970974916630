/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getTotalCoversByMonthAndYear } from '../../../../../../functions/getTotalCoversByMonthAndYear';

const TotalCoversByMonthAndYear = () => {
	const { organization } = useContext(OrganizationContext);
	const [chartData, setChartData] = useState({ labels: [], datasets: [] });

	const lineColors = [
		'#FF6384',
		'#36A2EB',
		'#FFCE56',
		'#4BC0C0',
		'#9966FF',
		'#FF9F40',
		'#FF6384',
		'#36A2EB',
		'#FFCE56',
		'#4BC0C0',
		'#9966FF',
		'#FF9F40',
	];

	useEffect(() => {
		async function fetchData() {
			const response = await getTotalCoversByMonthAndYear(organization._id);
			processData(response);
		}

		const processData = (data) => {
			const months = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			];

			const datasets = [];

			Object.entries(data).forEach(([_, { year, months }]) => {
				datasets.push({
					label: `Year ${year}`,
					data: months,
					borderColor: lineColors[year % lineColors.length],
					backgroundColor: 'transparent',
				});
			});

			setChartData({ labels: months, datasets });
		};

		fetchData();
	}, [organization]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top',
			},

			datalabels: {
				display: true,
				color: 'black',
			},
		},
		scales: {
			y: {
				title: {
					display: true,
					text: 'Avg Food Recycled Per Cover (Ounces)',
				},
				beginAtZero: true,
			},
			x: {
				title: {
					display: true,
					text: 'Month',
				},
			},
		},
	};

	return (
		<Stack
			sx={{
				height: '35vh',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Typography variant='h6' fontWeight='600' alignSelf='flex-start'>
				Total Covers By Month and Year
			</Typography>
			<Box sx={{ height: '100%', width: '100%' }}>
				<Line options={options} data={chartData} />
			</Box>
		</Stack>
	);
};

export default TotalCoversByMonthAndYear;
