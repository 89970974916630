import { Box, Paper } from '@mui/material';
import React from 'react';

const Card = ({ children, paperStyles, boxStyles, onClick }) => {
	return (
		<Paper
			onClick={onClick}
			sx={{
				gap: '1rem',
				borderRadius: '1rem',
				border: (theme) => `1px solid ${theme.palette.border.main}`,
				boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)',
				...paperStyles,
			}}>
			<Box
				sx={{
					padding: '0.5rem 1rem 0.5rem 1rem ',
					...boxStyles,
				}}>
				{children}
			</Box>
		</Paper>
	);
};

export default Card;
