import axios from 'axios';

export const addITProvider = async (provider, locationID) => {
	const url = `/.netlify/functions/addITProvider`;

	try {
		const res = await axios.post(url, { provider, locationID });
		return res.data;
	} catch (err) {
		console.error('Error:', err);
		return { error: err.response?.data || 'An unknown error occurred' };
	}
};
