import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { addITSwitch } from '../../../../functions/addITSwitch';
import { addITPort } from '../../../../functions/addITPort';
import { addITDetails } from '../../../../functions/addITDetails';
import { getNetworkDetails } from '../../../../functions/getNetworkDetails';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';

const NetworkDetails = () => {
    const [switchName, setSwitchName] = useState('');
    const [portName, setPortName] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState('');
    const { organization } = useContext(OrganizationContext);
    const { userLocation } = useContext(UserLocationContext);

    const fetchNetworkDetails = async () => {
        try {
            console.log('Fetching network details for:', userLocation._id);
            const networkDetails = await getNetworkDetails(userLocation._id);

            // Ensure the response is properly handled
            if (networkDetails) {
                console.log('Fetched network details:', networkDetails);
                setSwitchName(networkDetails.ITSwitch || ''); // Populate switch name
                setPortName(networkDetails.ITPort || ''); // Populate port name
                setAdditionalDetails(networkDetails.ITDetails || ''); // Populate additional details
            }
        } catch (error) {
            console.error('Error fetching network details:', error);
        }
    };

    useEffect(() => {
        if (userLocation?._id) {
            fetchNetworkDetails();
        }
    }, [userLocation?._id]);

    const handleSwitchSubmit = async () => {
        try {
            await addITSwitch(switchName, userLocation._id);
            console.log('Submitted Switch Name:', switchName);
        } catch (error) {
            console.error('Error submitting switch name:', error);
        }
    };

    const handlePortSubmit = async () => {
        try {
            await addITPort(portName, userLocation._id);
            console.log('Submitted Port Name:', portName);
        } catch (error) {
            console.error('Error submitting port name:', error);
        }
    };

    const handleDetailsSubmit = async () => {
        try {
            await addITDetails(additionalDetails, userLocation._id);
            console.log('Submitted Additional Details:', additionalDetails);
        } catch (error) {
            console.error('Error submitting additional details:', error);
        }
    };

    return (
        <Stack mx={5}>
            <Typography variant="h4" fontWeight="600">
                Network Hardware
            </Typography>
            <Typography variant="h6" mt={1}>
                Once the ethernet is run and connected, please let us know the Switch & Port information.
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 4 }}>
                {/* Row 1: Two Columns */}
                <Box sx={{ display: 'flex', gap: 4 }}>
                    {/* First column: Switch Name/Number */}
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                            Switch Name/Number
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <TextField
                                label="Enter Switch Name/Number"
                                variant="outlined"
                                value={switchName}
                                onChange={(e) => setSwitchName(e.target.value)}
                                sx={{ flex: 1 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSwitchSubmit}
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>

                    {/* Second column: Port Name/Number */}
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                            Port Name/Number
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <TextField
                                label="Enter Port Name/Number"
                                variant="outlined"
                                value={portName}
                                onChange={(e) => setPortName(e.target.value)}
                                sx={{ flex: 1 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePortSubmit}
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>

                {/* Row 2: Additional Information */}
                <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                        Additional Information
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                        <TextField
                            label="Add any further details (VLAN information, issues, comments, etc.)"
                            variant="outlined"
                            value={additionalDetails}
                            onChange={(e) => setAdditionalDetails(e.target.value)}
                            sx={{ flex: 1 }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDetailsSubmit}
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Stack>
    );
};

export default NetworkDetails;
