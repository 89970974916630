import React, { useState } from 'react';
import { Tabs, Tab, Box, Divider } from '@mui/material';
import Renders from './components/Renders/Renders';
import ITPreferences from './components/Network/Network';
import MachineCutsheet from './components/MachineCutsheet/MachineCutsheet';
import SpaceReady from './components/SpaceReady/SpaceReady';
import ContainerCutsheet from './components/ContainerCutsheet/ContainerCutsheet';
import CoverEntry from './components/CoverEntry/CoverEntry';
import UploadLogo from './components/UploadLogo/UploadLogo';
import LaunchDate from './components/LaunchDate/LaunchDate';
import NetworkProvider from './components/Network/NetworkProvider';
import Users from './components/Users/Users';
import Training from './components/Training/Training';
import LanguageEntry from './components/LanguageEntry/LanguageEntry';
import NetworkDetails from './components/Network/NetworkDetails';

const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

const PreLaunch = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <Box sx={{ m: '1rem' }}>
            <LaunchDate />
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{ my: 2, borderBottom: 1, borderColor: 'divider' }}
            >
                <Tab label="Engineering" sx={{ fontSize: '1.1rem', fontWeight: '500' }} />
                <Tab label="Networking" sx={{ fontSize: '1.1rem', fontWeight: '500' }} />
                <Tab label="Food & Beverage" sx={{ fontSize: '1.1rem', fontWeight: '500' }} />
                <Tab label="Users & Training" sx={{ fontSize: '1.1rem', fontWeight: '500' }} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                {/* Engineering Tab Content */}
                <Renders />
                <Divider sx={{ my: '2rem' }} />
                <SpaceReady />
                <Divider sx={{ my: '2rem' }} />
                <MachineCutsheet />
                <Divider sx={{ my: '2rem' }} />
                <ContainerCutsheet />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                {/* Networking Tab Content */}
                <ITPreferences />
                <Divider sx={{ my: '2rem' }} />
                <NetworkProvider />
                <Divider sx={{ my: '2rem' }} />
                <NetworkDetails />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                {/* Food & Beverage Tab Content */}
                <CoverEntry />
                <Divider sx={{ my: '2rem' }} />
                <UploadLogo />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
            <Users/>
            <Divider sx={{ my: '2rem' }} />
            <LanguageEntry/>
            <Divider sx={{ my: '2rem' }} />
            <Training />
            </TabPanel>
        </Box>
    );
};

export default PreLaunch;
