import React, { useState } from 'react';
import { Button, Paper, Typography, useTheme, Stack } from '@mui/material';
import OtherLanguages from '../../../Training/OtherLanguages';

// Tabs and corresponding data
const tabs = [
	{ label: 'Platform Overview', type: 'video' },
	{ label: 'General', type: 'pdf', file: '/assets/general.pdf' },
	{ label: 'Containers', type: 'pdf', file: '/assets/containers.pdf' },
	{ label: 'Site Champion', type: 'pdf', file: '/assets/sitechamp.pdf' },
];

// Video URL for the "Platform Overview" tab with translation options enabled
const videoURL =
	'https://www.youtube.com/embed/oJAm9Ys_JVU?cc_lang_pref=en&cc_load_policy=1&list=PLA1Md5KJwcnC5GBoUQdZALm7kSUrwDxVz';

// Styles for the iframe and buttons
const useStyles = (currentTab, theme) => ({
	iframeContainer: {
		position: 'relative',
		width: '100%',
		overflow: 'hidden',
		paddingTop: '56.25%', // 16:9 aspect ratio for video
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	responsiveIframe: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '75%',
		height: '75%',
		transform: 'translate(-50%, -50%)', // Centering the iframe
	},
	pdfContainer: {
		width: '80%',
		height: '80vh',
		marginTop: '1rem',
		paddingBottom: '5rem', // Add padding at the bottom
	},
	button: (tabName) => ({
		color: currentTab === tabName ? '#58b481' : '#000',
		backgroundColor: currentTab === tabName ? '#d5f0e1' : 'transparent',
		margin: '1rem',
		'&:hover': {
			backgroundColor: currentTab === tabName ? '#d5f0e1' : 'transparent',
		},
	}),
});

// Reusable TabButton component
const TabButton = ({ label, tabName, setCurrentTab, styles }) => (
	<Button sx={styles.button(tabName)} onClick={() => setCurrentTab(tabName)}>
		<Typography variant='body1' fontWeight='500'>
			{label}
		</Typography>
	</Button>
);

const Training = () => {
	const [currentTab, setCurrentTab] = useState('Platform Overview');
	const theme = useTheme(); // Access theme for dynamic styling
	const styles = useStyles(currentTab, theme); // Generate styles

	// Get current tab data
	const currentTabData = tabs.find((tab) => tab.label === currentTab);

	return (
		<Stack mx={5} spacing={2}>
			{/* Existing headers */}
			<Typography variant="h4" fontWeight="600">
				Training
			</Typography>
			<Typography variant="h6">
				Get a head start on machine training by reviewing these modules.
			</Typography>

			{/* Main content */}
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					margin: '1rem',
					borderRadius: '1rem',
					border: `1px solid ${theme.palette.border.main}`,
				}}>
				{/* Tabs for selecting different training materials */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						margin: '1rem',
					}}>
					<div
						style={{
							borderRadius: '1rem',
							border: `1px solid ${theme.palette.border.main}`,
							marginTop: '1rem',
						}}>
						{tabs.map(({ label }) => (
							<TabButton
								key={label}
								label={label}
								tabName={label}
								setCurrentTab={setCurrentTab}
								styles={styles}
							/>
						))}
					</div>
				</div>

				{/* Content display */}
				<div
					style={{
						height: '100%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '3rem',
					}}>
					<Typography variant="h5" fontWeight="500">
						{currentTab === 'Platform Overview'
							? 'Platform Overview Video'
							: `${currentTab} Training Slideshow`}
					</Typography>
					{currentTabData.type === 'video' ? (
						// Display video for "Platform Overview"
						<div style={styles.iframeContainer}>
							<iframe
								style={styles.responsiveIframe}
								src={videoURL}
								title="Platform Overview"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							/>
						</div>
					) : (
						// Display PDF for other tabs
						<div style={styles.pdfContainer}>
							<iframe
								src={`${currentTabData.file}#toolbar=0&navpanes=0&scrollbar=0`}
								width="100%"
								height="100%"
								frameBorder="0"
								title={currentTab}
							/>
						</div>
					)}
				</div>
                {currentTab === 'Platform Overview' && <OtherLanguages />}
			</Paper>
		</Stack>

	);
};

export default Training;
