import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Stack, TextField, Typography, ListItem, List } from '@mui/material';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';
import { useLocation } from 'react-router-dom';
import { requestLanguage } from '../../../../functions/requestLanguage';

const LanguageEntry = () => {
    const [language, setLanguage] = useState(''); 
    const { organization } = useContext(OrganizationContext);
    const { userLocation } = useContext(UserLocationContext);

    const handleSubmit = async () => {
        try {
            await requestLanguage(language, userLocation._id);
            console.log('Submitted language:', language);

            // Clear the input field after successful submission
            setLanguage('');
        } catch (error) {
            console.error('Error submitting provider:', error);
        }
    };

    return (
        <Stack mx={5}>
            <Typography variant="h4" fontWeight="600">
                User Languages
            </Typography>
            <Typography variant="h6" mt={1}>
                The following are the languages our HMI comes equipped with:
            </Typography>
			<Box sx={{ mt: 2 }}>
				<List>
				<ListItem>
						• English
					</ListItem>
					<ListItem>
						• Spanish
					</ListItem>
					<ListItem>
						• French
					</ListItem>
                    <ListItem>
						• Portuguese
					</ListItem>
                    <ListItem>
						• Vietnamese
					</ListItem>
                    <ListItem>
						• Arabic
					</ListItem>
				</List>
			</Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                    label="Let us know if you need another language provided"
                    variant="outlined"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    sx={{ flex: 1 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    Submit
                </Button>
            </Box>
        </Stack>
    );
};

export default LanguageEntry;
