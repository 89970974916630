import { Chip, Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material';

import AcresFertilized from './components/AcresFertilized';
import TreesPlanted from './components/TreesPlanted';
import HomesPowered from './components/HomesPowered';
import MilesDriven from './components/MilesDriven';
import Card from '../../../../../../components/Card';

const CarbonEquivalents = () => {
	const theme = useTheme();

	return (
		<Stack direction='column' gap='1rem'>
			<Stack
				direction={{ xs: 'column', lg: 'row' }} // 'column' for xs to md breakpoints, 'row' for lg and above
				alignItems={'center'}
				gap='1rem'
				style={{
					padding: '0.5rem 1rem 0.5rem 1rem',
				}}>
				<Typography variant='h5' fontWeight='600'>
					All-Time Carbon Offset Equivalents
				</Typography>

				<Tooltip title='Open in new tab' placement='bottom'>
					<Chip
						variant='outlined'
						label='US EPA Equivalencies References'
						sx={{
							borderColor: theme.palette.buttonLink.main,
							backgroundColor: 'hsl(125,22%,90%)',
							color: 'hsl(125,22%,50%)',
						}}
						onClick={() =>
							window.open(
								'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references',
								'_blank'
							)
						}
					/>
				</Tooltip>
			</Stack>
			<Grid container spacing='1rem'>
				<Grid item xs={12} md={12} lg={3}>
					<Card>
						<AcresFertilized />
					</Card>
				</Grid>
				<Grid item xs={12} md={12} lg={3}>
					<Card>
						<TreesPlanted />
					</Card>
				</Grid>
				<Grid item xs={12} md={12} lg={3}>
					<Card>
						<HomesPowered />
					</Card>
				</Grid>
				<Grid item xs={12} md={12} lg={3}>
					<Card>
						<MilesDriven />
					</Card>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default CarbonEquivalents;
