import { Stack, Typography, Box, List, ListItem } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import React, { useContext } from 'react';
import { useTheme } from '@mui/material/styles'; // Import useTheme to access theme
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';

const LaunchDate = () => {
    const theme = useTheme(); // Access the theme
    const { organization } = useContext(OrganizationContext);
    const { userLocation } = useContext(UserLocationContext)

    // const estimatedGoLiveDate = organization?.estimatedGoLive
    //     ? new Date(organization.estimatedGoLive).toLocaleDateString('en-US', { timeZone: 'UTC' })
    //     : null; // Properly handles the string format

    const estimatedGoLiveDate = userLocation?.targetDate
        ? new Date(userLocation.targetDate).toLocaleDateString('en-US', { timeZone: 'UTC' })
        : null; // Properly handles the string format
        
    return (
        <Stack mx={5}>
            <Box display="flex" alignItems="center" gap={2}>
                <RocketLaunchIcon fontSize="large" sx={{ color: theme.typography.h3.color }} /> {/* Icon color matches h3 */}
                <Typography variant="h3" fontWeight="600">
                    Pre-Launch
                </Typography>
            </Box>
            <Typography variant="h5" mt={1}>
                Prepare to divert your food waste with our Food Cycling™ Platform.
            </Typography>
            <Typography variant="h5">
                Your estimated BG3 install date is{' '}
                {estimatedGoLiveDate ? (
                    <Typography component="span" variant="h5" fontWeight="600" sx={{ color: theme.typography.h3.color }}>
                        {estimatedGoLiveDate}
                    </Typography>
                ) : (
                    <Typography component="span" variant="h5" color="error" fontWeight="600">
                        to be determined
                    </Typography>
                )}
                .
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Typography variant="body1">
                    Below are the items to be reviewed and completed prior to your install. Select the appropriate category from the tabs below to view your pre-launch items.
                </Typography>
            </Box>
        </Stack>
    );
};

export default LaunchDate;
