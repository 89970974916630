import { Typography } from '@mui/material';
import React from 'react';

const OutletHeaderSection = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography variant='h6' fontWeight='600' sx={{ textAlign: 'center' }}>
				Create Outlets
			</Typography>
			<ul>
				<li>
					<Typography variant='p' fontWeight='600'>
						Outlets are locations on site where food waste is generated. Most often, these are restaurants, kitchens, etc.
					</Typography>
				</li>
				<li>
					<Typography variant='p' fontWeight='600'>
						The reason for logging outlets is so that you can record project and actual covers for a given outlet, so make sure each location has its own cover counts. 
					</Typography>
				</li>
				<li>
					<Typography variant='p' fontWeight='600'>
						These outlets will also be used on the BG3 as options for food waste when loading the machine.
					</Typography>
				</li>
				<li>
					<Typography variant='p' fontWeight='600'>
						If you have any issues or questions about outlets, please contact your BioGreen360 representative.
					</Typography>
				</li>
			</ul>
		</div>
	);
};

export default OutletHeaderSection;
