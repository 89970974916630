import { useContext, useEffect, useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getOrgsByIds } from '../../functions/getOrgsByIds';
import { showPreLaunch } from '../../../../utils/showPreLaunch';
import compliance from '../../../../assets/examples/aggScreenshots/compliance.png';
import CoversStatus from './components/CoversStatus';
import TimeSincelastFeed from './components/TimeSincelastFeed';
import Utilization from './components/Utilization';
import ActiveUsers from './components/ActiveUsers';
import OutletBreakdown from './components/OutletBreakdown';
import Uptime from './components/Uptime';
import AvgFeedWeight from './components/AvgFeedWeight';
import AvgFeedsPerDay from './components/AvgFeedsPerDay';
import Downtime from './components/Downtime';
import ExamplePageWarning from '../../../CustomerDashboard/components/ExamplePageWarning/ExamplePageWarning';

const Compliance = () => {
	const { organization } = useContext(OrganizationContext);
	const [orgObjects, setOrgObjects] = useState([]);
	const [selectedOrg, setSelectedOrg] = useState('');

	const handleChange = (event) => {
		setSelectedOrg(event.target.value);
	};

	useEffect(() => {
		async function fetchData() {
			if (organization?.children?.length > 0) {
				const response = await getOrgsByIds(organization.children, false);
				setOrgObjects(response);
				if (response.length > 0) {
					setSelectedOrg(response[0]);
				}
			}
		}

		fetchData();
	}, [organization]);

	if (!showPreLaunch(organization)) {
		return (
			<Box marginX='2rem' marginBottom='3rem'>
				<FormControl sx={{ minWidth: '200px', mb: '1rem' }}>
					<InputLabel id='demo-simple-select-label'>Organization</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
						value={selectedOrg || ''}
						label='Organization'
						onChange={handleChange}>
						{orgObjects.map((object, index) => (
							<MenuItem key={index} value={object}>
								{object.org}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12} lg={4}>
						<CoversStatus selectedOrg={selectedOrg} />
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<Utilization orgID={selectedOrg._id} />
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<Uptime orgID={selectedOrg._id} />
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<ActiveUsers orgID={selectedOrg._id} />
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<TimeSincelastFeed orgID={selectedOrg._id} />
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<Downtime orgID={selectedOrg._id} />
					</Grid>
					<Grid item xs={12} md={12} lg={8}>
						<OutletBreakdown orgID={selectedOrg._id} />
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<Stack direction='column' gap='1rem' height='100%'>
							<AvgFeedWeight orgID={selectedOrg._id} />
							<AvgFeedsPerDay orgID={selectedOrg._id} />
						</Stack>
					</Grid>
				</Grid>
			</Box>
		);
	} else {
		return (
			<>
				<ExamplePageWarning />
				<img src={compliance} alt='compliance'></img>
			</>
		);
	}
};

export default Compliance;
