import { Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox CSS
import { getMapCoordinates } from '../../../../functions/getMapCoordinates';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { useTheme } from '../../../../../../context/ThemeContext';

mapboxgl.accessToken =
	'pk.eyJ1IjoiYnZpc2Nvc2kiLCJhIjoiY2xxNzJqcDV0MGZwNjJrbno3c240cXV0ayJ9.Tz_CaaSh-OTldaLIgs9krg';

const Marker = ({ children }) => {
	return <div style={{ fontSize: '24px' }}>{children}</div>;
};

const UserLocationMap = () => {
	const { theme } = useTheme(); // Assuming theme is an object with a mode property
	const { organization } = useContext(OrganizationContext);
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [location, setLocation] = useState(null);

	useEffect(() => {
		const abortController = new AbortController(); // Create an instance of AbortController
		const { signal } = abortController; // Get the signal from the controller

		async function fetchData() {
			try {
				const response = await getMapCoordinates(organization.org, { signal }); // Pass the signal to fetch call
				if (signal.aborted) return; // Check if the fetch was aborted and exit if so

				setLocation(response);

				if (response && response.locationLat && response.locationLong && !map.current) {
					const mapStyle =
						theme.palette.mode === 'light'
							? 'mapbox://styles/mapbox/light-v10'
							: 'mapbox://styles/mapbox/dark-v10';

					map.current = new mapboxgl.Map({
						container: mapContainer.current,
						style: mapStyle,
						center: [response.locationLong, response.locationLat],
						zoom: 12,
					});
				}
			} catch (error) {
				if (error.name !== 'AbortError') {
					console.error('Fetch error:', error);
				}
			}
		}

		fetchData();

		return () => {
			abortController.abort(); // Abort fetch on cleanup
			if (map.current) {
				map.current.remove(); // Clean up map instance
				map.current = null;
			}
		};
	}, [organization, theme.palette.mode]);

	useEffect(() => {
		if (location && location.locationLat && location.locationLong && map.current) {
			// Create a new DOM element for the marker
			const markerNode = document.createElement('div');
			// Use createRoot to render the Marker component into the DOM element
			const root = createRoot(markerNode);
			root.render(<Marker>🌱</Marker>);

			// Create a new marker and set its position
			new mapboxgl.Marker(markerNode)
				.setLngLat([location.locationLong, location.locationLat])
				.addTo(map.current);
		}
	}, [location]);

	useEffect(() => {
		if (map.current) {
			// Apply the theme-based style to the map
			const mapStyle =
				theme.palette.mode === 'light'
					? 'mapbox://styles/mapbox/light-v10'
					: 'mapbox://styles/mapbox/dark-v10';

			map.current.setStyle(mapStyle);
		}
		// Clean up the map instance to avoid memory leaks
		return () => {
			if (map.current) {
				map.current.remove();
				map.current = null;
			}
		};
	}, [theme.palette.mode]); // This useEffect runs when theme mode changes

	return (
		<Paper
			style={{
				display: 'flex',
				width: '100%',
				height: '100%',
				border: `1px solid ${theme.palette.border.main}`,
				boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)', // More subtle soft shadow
				borderRadius: '1rem',
				overflow: 'hidden', // Ensures nothing spills outside the container
			}}>
			<div ref={mapContainer} style={{ width: '100%' }} />
		</Paper>
	);
};

export default UserLocationMap;
