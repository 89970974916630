import { Stack, Typography, Box } from '@mui/material';
import technical_cutsheet from '../../../../../../assets/BG3 GEN 4 TECHNICAL CUTSHEET v1.5.pdf';

const MachineCutsheet = () => {
	return (
		<Stack mx={5}>
			<Typography variant="h4" fontWeight="600">
				Machine Technical Cutsheet
			</Typography>
			<Typography variant="h6">Review the machine technical cutsheet</Typography>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: '2rem',
				}}
			>
				<iframe
					title="technical cutsheet"
					src={`${technical_cutsheet}#toolbar=0&scrollbar=0`}
					style={{
						width: '69%',
						height: '1070px',
						border: 'none',
					}}
				/>
			</Box>
		</Stack>
	);
};

export default MachineCutsheet;
