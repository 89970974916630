import { Typography } from "@mui/material";
import React from "react";

const ProjectedTableMessaging = ({ mo, yr, rws }) => {
  // Conditional rendering based on the props
  return !(mo && yr) ? (
    <Typography
      variant="p"
      fontWeight={"600"}
      sx={{
        display: "flex",
        color: "red",
        width: "50%",
        marginBottom: "1rem",
        marginTop: "1rem",
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      Please specify the timeframe of interest using the selectors above.
    </Typography>
  ) : (
    <Typography
      variant="p"
      fontWeight={"700"}
      sx={{
        display: "flex",
        color: "red",
        width: "50%",
        marginBottom: "1rem",
        marginTop: "1rem",
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      Please make sure the information being uploaded is accurate before saving!
    </Typography>
  );
};

export default ProjectedTableMessaging;
