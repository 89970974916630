import { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getMissingProjCovers } from '../../../../../../functions/getMissingProjCovers';
import { getCoverChampions } from '../../../../functions/getCoverChampions';

const MissingCoversMessage = () => {
	const { organization } = useContext(OrganizationContext);
	const [missingMonths, setMissingMonths] = useState([]);
	const [coversChampions, setCoversChampions] = useState();

	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	useEffect(() => {
		async function callGetCoverIssues() {
			const response = await getMissingProjCovers(organization._id);
			if (response && response.length > 0) {
				const uniqueDates = new Set(
					response.map((missingMonthYearPair) => {
						return `${monthNames[missingMonthYearPair.month - 1]} ${
							missingMonthYearPair.year
						}`;
					})
				);
				const sortedDates = Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
				setMissingMonths(sortedDates);
			}
		}

		callGetCoverIssues();
	}, [organization]);

	useEffect(() => {
		const fetch = async () => {
			const response = await getCoverChampions(organization.org);
			setCoversChampions(response);
		};
		if (organization) fetch();
	}, [organization]);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				textAlign: 'center',
				alignItems: 'center',
			}}>
			{/* Corrected styling here */}
			<Typography
				variant='p'
				fontWeight={'700'}
				sx={{
					display: 'flex',
					color: missingMonths.length > 0 ? 'red' : 'green',
					width: '70%',
					marginBottom: '1rem',
					marginTop: '1rem',
					paddingTop: '0.25rem',
					paddingBottom: '0.25rem',
					flexDirection: 'column',
					textAlign: 'center',
				}}>
				{missingMonths.length > 0
					? `The following months are missing: ${missingMonths.join(', ')}`
					: 'No missing months found, good job!'}
			</Typography>
			{coversChampions?.length ? (
				<Typography sx={{ color: missingMonths.length > 0 ? 'red' : 'green' }}>
					Contact{' '}
					{`${coversChampions[0].FIRST} ${coversChampions[0].LAST} for help updating covers`}
				</Typography>
			) : (
				<></>
			)}
		</div>
	);
};

export default MissingCoversMessage;
