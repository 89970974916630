import React, { useState, useContext, useEffect } from 'react';
import { Typography, useTheme, LinearProgress, fabClasses } from '@mui/material';
import styles from './styles';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getLastFeed } from '../../functions/getLastFeed';
import { getLbsFedToday } from '../../functions/getLbsFedToday';
import { formatDistanceToNowStrict } from 'date-fns';
import { useTranslation } from 'react-i18next';

const RecentFeedStatus = () => {
	const theme = useTheme();
	const classes = styles(theme);

	const [User, setUser] = useState();
	const [Weight, setWeight] = useState();
	const [Time, setTime] = useState();
	const [TotalLbs, setTotalLbs] = useState();

	const { organization } = useContext(OrganizationContext);
	const { t } = useTranslation();

	useEffect(() => {
		async function fetchData() {
			const response = await getLastFeed(organization._id);
			const responselbs = await getLbsFedToday(organization._id);
			setUser(response.user);
			setWeight(Math.round(response.weight));
			const timeAgoString = formatDistanceToNowStrict(new Date(response.timestamp), {
				addSuffix: true,
			});
			setTime(timeAgoString);
			setTotalLbs(Math.round(responselbs.totalWeight));
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, [organization]);

	return (
		<div style={classes.recentFeedsCard}>
			<div style={classes.cardTitle}>
				<Typography variant='h5' fontWeight={'bold'}>
					{t('Recent Feeds')}
				</Typography>
			</div>
			<Typography variant='h6' style={{ flex: 1, paddingLeft: '1rem' }}>
				{t('Last feed')} <strong>{Time}</strong> {t('by')} <strong>{User}</strong>{' '}
				{t('consisting of')} <strong>{Weight} lbs.</strong>
			</Typography>
			<Typography variant='h6' style={{ flex: 1, paddingRight: '1rem', textAlign: 'right' }}>
				<strong>{TotalLbs}</strong> {t('lbs fed today')}
			</Typography>
		</div>
	);
};

export default RecentFeedStatus;
