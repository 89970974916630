import { Paper, Typography, useTheme, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import UsersTable from '../../../UserManagement/UsersTable';
import AddUpdateUser from '../../../UserManagement/AddUpdateUser';
import { getUsersByOrganization } from '../../../../functions/getUsersByOrganization';
import styles from '../../../UserManagement/styles'; // Import consistent styles

const Users = () => {
    const { organization } = useContext(OrganizationContext);
    const theme = useTheme();
    const classes = styles(theme); // Use styles from UserManagement

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (!organization?.org) {
                console.error('Organization is undefined or invalid:', organization);
                return;
            }

            try {
                const response = await getUsersByOrganization(organization.org);
                if (response?.users) {
                    setUsers(response.users);
                } else {
                    setUsers([]);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
                setUsers([]);
            }
        }
        fetchData();
    }, [organization]);

    const refreshUsers = async () => {
        try {
            const response = await getUsersByOrganization(organization.org);
            if (response?.users) {
                setUsers(response.users);
            }
        } catch (error) {
            console.error('Error refreshing users:', error);
        }
    };

    return (
        <Stack mx={5}>
        <Typography variant="h4" fontWeight="600">
        Users
    </Typography>
    <Typography variant="h6" mt={1}>
        Preload any users that will be operating the machine or need cloud access.
    </Typography>
		<Paper sx={classes.canvas}> {/* Removed padding/margin conflicts */}
			<div style={classes.body}>
				<Typography variant="h4" fontWeight="600">
					User Management
				</Typography>
				<UsersTable
					users={users}
					classes={classes}
					selectedUser={selectedUser}
					setSelectedUser={setSelectedUser}
					disablePermissions // Disable permissions for PreLaunch context
				/>
				<AddUpdateUser
					selectedUser={selectedUser}
					setSelectedUser={setSelectedUser}
					refreshUsers={refreshUsers}
				/>
			</div>
		</Paper>
        </Stack>
    );
};

export default Users;
